<template>
  <v-container height="100%" fluid grid-list-xl>
    <v-layout row wrap justify-center>
      <v-flex expand>
        <component :is="component.component" @close="dialog = false"></component>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import PrintManagement from '@/components/settings/PrintManagement.vue'

export default {
  name: 'Playground',
  // import your component here with the same name as the one in your component object in the
  // navigationButtons array
  components: {
    'print-management': PrintManagement
  },
  data () {
    return {
      dialog: true,
      orderId: '',
      printer: '',
      component: {
        name: 'Print Management',
        component: 'print-management'
      },

      searchBy: 'company',

      // navigationButtons: [
      //   { name: 'Report Builder', component: 'builder', props: { data: {} } },
      //   {
      //     name: 'Report Viewer',
      //     component: 'report-viewer',
      //     props: {
      //       data: {
      //         labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      //         datasets: [
      //           {
      //             label: 'Company Sales A',
      //             borderColor: colors.green.accent3,
      //             pointBackGroundColor: colors.blue.base,
      //             fill: false,
      //             data: [40, 20, 30, 39, 55, 40, 39, 80, 40, 20, 12, 11]
      //           },
      //           {
      //             label: 'Company Sales B',
      //             borderColor: colors.blue.base,
      //             pointBackGroundColor: colors.green.accent3,
      //             fill: false,
      //             data: [12, 23, 45, 66, 34, 87, 45, 34, 56, 45, 23, 99]
      //           },
      //           {
      //             label: 'Company Sales C',
      //             borderColor: colors.red.base,
      //             pointBackGroundColor: colors.grey.base,
      //             fill: false,
      //             data: [12, 40, 45, 12, 34, 87, 10, 34, 56, 45, 23, 99]
      //           },
      //           {
      //             label: 'Company Sales D'
      //           },
      //           {
      //             label: 'Company Sales E'
      //           },
      //           {
      //             label: 'Company Sales F'
      //           },
      //           {
      //             label: 'Company Sales G'
      //           },
      //           {
      //             label: 'Company Sales H'
      //           },
      //           {
      //             label: 'Company Sales I'
      //           },
      //           {
      //             label: 'Company Sales J'
      //           },
      //           {
      //             label: 'Company Sales K'
      //           },
      //           {
      //             label: 'Company Sales L'
      //           },
      //           {
      //             label: 'Company Sales M'
      //           }
      //         ]
      //       },
      //       options: { title: { display: true, text: 'Clients by Sales Margin' } },
      //       isTesting: true
      //     }
      //   },
      //   {
      //     name: 'Reports List',
      //     component: 'reports',
      //     props: {
      //     }
      //   }
      // ],
      //
      // buttons: [
      //   { name: '+5', icon: 'fal fa-arrow-to-top', iconColor: 'primary', handler: (chart, data) => this.showFive(chart, data, true) },
      //   { name: '-5', icon: 'fal fa-arrow-to-bottom', iconColor: 'primary', handler: (chart, data) => this.showFive(chart, data, false) },
      //   { name: 'Average', icon: 'fal fa-tachometer-alt-average', iconColor: 'primary', handler: (chart, data) => this.showAverage(chart, data) },
      //   { name: 'All', icon: '', handler: (chart, data) => this.showAll(chart, data) }
      // ],
      //
      // type: 'bar',

      // shipping-card variables
      show: false // for the dialog
    }
  },
  methods: {
    showFive (chart, data, top) {
      for (let i = 0; i < data.datasets.length; i++) {
        const s = data.datasets[i]
        s.hidden = s.label !== 'Average' && (top ? i > 6 : i < data.datasets.length - 5)
      }
      chart.update()
    },

    showAverage (chart, data) {
      data.datasets.forEach(s => {
        s.hidden = s.label !== 'Average'
      })
      chart.update()
    },

    showAll (chart, data) {
      data.datasets.forEach(s => {
        s.hidden = false
      })
      chart.update()
    }
  },
  mounted () {
    // set the component to be whatever the first component in the array is
    // this.component = this.navigationButtons?.[0] ?? { name: 'No Component Listed', component: '', props: null }
  }
}
</script>
