







































































































import { computed, defineComponent, provide, ref } from '@vue/composition-api'
import { ApolloClients } from '@vue/apollo-composable'
import { apolloClient } from '@/api/graphql/apollo.js'
import { PrintNodePrinter, useGetPrintNodePrintersQuery } from '@/models/generated/graphql/ErpBackend'
import { useTAlert } from '@/composition/UseTAlert'
import { IVuetifyDatatableHeader } from '@/models/VuetifyModels'
import tAlert from '@/components/notifications/tAlert.vue'
import store from '@/store/store'
import { UpdateProfileSettings } from '@/api/graphql/Constants/Profile'
import { Sentry } from '@/lib/Sentry'

export default defineComponent({
  name: 'PrintManagement',
  components: {
    't-alert': tAlert
  },
  setup () {
    provide(ApolloClients, {
      default: apolloClient
    })

    const { alert } = useTAlert()
    const search = ref('')
    const headers: IVuetifyDatatableHeader[] = [
      { text: 'Printer Name', value: 'name' },
      { text: 'Attached Computer', value: 'computer.name' },
      { text: 'Printer Status', value: 'state' },
      { text: 'Actions', value: '', sortable: false }
    ]

    const {
      result,
      loading,
      onError,
      refetch
    } = useGetPrintNodePrintersQuery()
    onError((e) => {
      alert.setter({ message: e.message, type: 'error' })
    })
    const printers = computed(() => result?.value?.printers ?? [])
    const countOfPrintersInErrorState = computed(() => {
      let count = 0
      for (const printer of printers.value) {
        const pState = printer.state
        const cState = printer.computer?.state ?? ''
        if ([pState, cState].indexOf('disconnected') > -1) {
          count++
        }
      }
      return count
    })
    const countOfPrintersOutOfPaper = computed(() => {
      let count = 0
      for (const printer of printers.value) {
        const pState = printer.state
        const cState = printer.computer?.state ?? ''
        if ([pState, cState].indexOf('out_of_paper') > -1) {
          count++
        }
      }
      return 1
    })

    const userSettings = computed(() => store.getters['data/settings'])
    const user = computed(() => store.getters['profile/user'])
    const defaultPrinterId = computed(() => userSettings.value.defaultPrinterId)

    const makeDefault = (item: PrintNodePrinter) => {
      let value
      if (item.id !== defaultPrinterId.value) value = item.id
      else value = 0
      store.dispatch('data/updateUserSettingsKey', { key: 'defaultPrinterId', value: value })
        .then((settings) => {
          try {
            UpdateProfileSettings(user.value.id, settings)
          } catch (e) {
            Sentry.setContext('profile_input', { ...userSettings.value, id: user.value.id })
            Sentry.captureEvent(e)
            alert.setter({ message: e.message, type: 'error' })
          }
        })
    }
    const getDefaultText = (item: PrintNodePrinter) => {
      if (item.id === defaultPrinterId.value) {
        return 'Remove Default'
      } else {
        return 'Set Default'
      }
    }

    const getDefaultColor = (item: PrintNodePrinter) => {
      if (item.id === defaultPrinterId.value) return 'warning'
      else return 'primary'
    }

    return {
      userSettings,
      alert,
      countOfPrintersInErrorState,
      countOfPrintersOutOfPaper,
      getDefaultColor,
      getDefaultText,
      headers,
      loading,
      makeDefault,
      printers,
      refresh: refetch,
      search
    }
  }
})
